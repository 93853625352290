import React, { useContext, useEffect } from "react";
import { ApplyStepsContext } from "../../contexts/ApplyStepsContext";
import IApplyStep from "../../interfaces/IApplyStep";
import { Typo10, Typo3, Typo7, Typo8 } from "../Typography";
import {
  ApplyInfoContent,
  ApplyInfoHeading,
  ApplyInfoItem,
  ApplyInfoWrapper,
  ApplyInfoTimeWrapper,
  ApplyInfoTimeIconWrapper,
  ApplyInfoText,
  ApplyInfoDot,
  ApplyInfoDotInner,
  ApplyInfoArrow,
  ApplyInfoItemHeading,
  ApplyInfoTransformWrapper,
} from "./styles";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  top: number;
  left: number;
  section: string;
}

const ApplyInfo: React.FC<Props> = (props) => {
  const { top, left, section } = props;

  const { steps: items, heading } = useContext(ApplyStepsContext);

  if (!items || items.length === 0) return null;

  return (
    <ApplyInfoTransformWrapper
      section={section}
      style={{ top: top, left: left }}
    >
      <ApplyInfoArrow section={section} />
      <ApplyInfoWrapper>
        <ApplyInfoHeading>
          <Typo10 color="#1B1B1B" fontWeight="600">
            {heading}
          </Typo10>
        </ApplyInfoHeading>
        <ApplyInfoContent>
          {items.map((item: IApplyStep) => {
            const { heading, time, text } = item;

            return (
              <ApplyInfoItem key={heading}>
                <ApplyInfoDot>
                  <ApplyInfoDotInner />
                </ApplyInfoDot>
                <ApplyInfoItemHeading>
                  <Typo7
                    color="#0B264C"
                    textAlign="left"
                    fontWeight="600"
                    lineHeight="16px"
                  >
                    {heading}
                  </Typo7>
                </ApplyInfoItemHeading>
                {time && (
                  <ApplyInfoTimeWrapper>
                    <ApplyInfoTimeIconWrapper>
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41671 0.166687C5.26299 0.166687 4.13517 0.508806 3.17588 1.14978C2.2166 1.79076 1.46892 2.7018 1.02741 3.7677C0.585902 4.8336 0.470382 6.00649 0.695463 7.13805C0.920543 8.2696 1.47611 9.309 2.29192 10.1248C3.10773 10.9406 4.14713 11.4962 5.27868 11.7213C6.41024 11.9463 7.58313 11.8308 8.64903 11.3893C9.71493 10.9478 10.626 10.2001 11.2669 9.24085C11.9079 8.28156 12.25 7.15374 12.25 6.00002C12.25 5.23398 12.0992 4.47543 11.806 3.7677C11.5129 3.05997 11.0832 2.41691 10.5415 1.87523C9.99982 1.33356 9.35676 0.903876 8.64903 0.610723C7.9413 0.317571 7.18275 0.166687 6.41671 0.166687ZM6.41671 10.6667C5.49373 10.6667 4.59148 10.393 3.82405 9.88021C3.05662 9.36743 2.45848 8.6386 2.10527 7.78588C1.75206 6.93315 1.65965 5.99484 1.83971 5.0896C2.01978 4.18435 2.46423 3.35283 3.11688 2.70019C3.76952 2.04754 4.60104 1.60309 5.50629 1.42302C6.41153 1.24296 7.34984 1.33537 8.20257 1.68858C9.05529 2.04179 9.78412 2.63993 10.2969 3.40736C10.8097 4.17479 11.0834 5.07704 11.0834 6.00002C11.0834 7.2377 10.5917 8.42468 9.71654 9.29985C8.84137 10.175 7.65439 10.6667 6.41671 10.6667ZM8.22504 6.36752L7.00004 5.66169V3.08335C7.00004 2.92864 6.93858 2.78027 6.82919 2.67087C6.71979 2.56148 6.57142 2.50002 6.41671 2.50002C6.262 2.50002 6.11363 2.56148 6.00423 2.67087C5.89483 2.78027 5.83338 2.92864 5.83338 3.08335V6.00002C5.83338 6.00002 5.83338 6.04669 5.83338 6.07002C5.83683 6.11021 5.84668 6.1496 5.86254 6.18669C5.87456 6.2213 5.8902 6.25454 5.90921 6.28585C5.92517 6.31901 5.94473 6.35031 5.96754 6.37919L6.06088 6.45502L6.11338 6.50752L7.63004 7.38252C7.71895 7.43291 7.81952 7.45906 7.92171 7.45835C8.05087 7.45926 8.17668 7.41727 8.2794 7.33897C8.38213 7.26067 8.45596 7.15049 8.48933 7.02571C8.52269 6.90093 8.51369 6.76861 8.46375 6.64949C8.41381 6.53037 8.32575 6.4312 8.21338 6.36752H8.22504Z"
                          fill="#658AC8"
                        />
                      </svg>
                    </ApplyInfoTimeIconWrapper>
                    <div style={{ marginLeft: "7px" }}>
                      <Typo8 color="#658AC8" fontWeight="500" textAlign="left">
                        {time}
                      </Typo8>
                    </div>
                  </ApplyInfoTimeWrapper>
                )}
                {text && (
                  <ApplyInfoText>
                    <Typo3 color="#1B1B1B" textAlign="left">
                      {text}
                    </Typo3>
                  </ApplyInfoText>
                )}
              </ApplyInfoItem>
            );
          })}
        </ApplyInfoContent>
      </ApplyInfoWrapper>
    </ApplyInfoTransformWrapper>
  );
};

export default ApplyInfo;
