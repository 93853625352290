import React from 'react';
import styled from 'styled-components';

interface Props { 

}

const Wrapper = styled.span`
  font-weight: 700;
`

const First = styled.span`
  color: ${({ theme }) => theme.colors.watermelon};
`

const Second = styled.span`
  color: #012147;
`

const TalentMatchBrand : React.FC<Props> = props => {

  const {} = props;

  return (
    <Wrapper>
      <First>
        Talent
      </First>
      <Second>
        Match
      </Second>
    </Wrapper>
  );
}

export default TalentMatchBrand;