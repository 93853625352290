import React from "react";
import { Link } from "gatsby";

import MoreArrow from "../../assets/svg/MoreArrow";
import { formatSalaryString } from "../../common/utils";

import StrapiImage from "../StrapiImage";
import { Typo7, Typo14, Typo15, Typo17, Typo18 } from "../Typography";

import JobListItemApplyNow from "../JobListItemApplyNow";

import IJob from "../../interfaces/IJob";

import {
  Wrapper,
  Row,
  LogoWrapper,
  SalaryTime,
  ClickTarget,
  Button,
  TechItems,
  ButtonSpace,
  DesktopHeading,
  MobileHeading,
  SalaryText,
  MobileTimeString,
  DesktopMainItem,
  DesktopButton,
  CellPadding,
  FullTimePoxtfix,
} from "./styles";

import {
  InformationCell,
  TechCell,
  LocationCell,
  TimeCell,
  TypeCell,
} from "../JobListCells";
import TechItem from "../TechItemBadge";
import HireWiseBrand from "../HireWireBrand";
import TalentMatchBrand from "../TalentMatchBrand";
import TypeBadge from "../TypeBadge";
import ITimezone from "../../interfaces/ITimezone";

interface IProps {
  job: IJob;
  first: boolean;
  hideInfo?: boolean;
  small?: boolean;
}

const JobListItemNew: React.FC<IProps> = ({ job, first, hideInfo, small }) => {
  const {
    name,
    slug,
    timezoneGMTMax,
    timezoneGMTMin,
    timezoneFormatted,
    timezoneCategory,
    mustHaveTechItems,
    company,
    applyUrl,
    isHireWise,
    customLocation,
  } = job;

  if (!slug) return null;

  const formattedSalary = formatSalaryString(job);

  const fromTimeNumber = timezoneCategory
    ? timezoneCategory.fromGMT
    : timezoneGMTMin;

  const toTimeNumber = timezoneCategory
    ? timezoneCategory.toGMT
    : timezoneGMTMax;

  const hasFrom =
    typeof fromTimeNumber !== "undefined" && fromTimeNumber !== null;
  const hasTo = typeof toTimeNumber !== "undefined" && toTimeNumber !== null;

  const unformattedTime =
    !hasFrom || !hasTo
      ? "All timezones"
      : `GMT${
          fromTimeNumber > 0 ? `+${fromTimeNumber}` : fromTimeNumber
        } - GMT${toTimeNumber > 0 ? `+${toTimeNumber}` : toTimeNumber}`;

  const time = unformattedTime.replace(/GMT0/g, "GMT");

  const { logo, name: companyName } = company || {};

  const hasTech = mustHaveTechItems && mustHaveTechItems.length > 0;

  return (
    <Wrapper first={first} className="JobItem">
      <MobileHeading>
        <Link to={`/job/${slug}/`}>
          <Typo14
            color="#658AC8"
            fontWeight="600"
            as={small ? "h3" : "h2"}
          >
            {name}
          </Typo14>
        </Link>
      </MobileHeading>
      <InformationCell>
        <Row>
          <LogoWrapper>
            {!logo ? null : <StrapiImage image={logo} />}
          </LogoWrapper>
          <SalaryTime>
            <DesktopHeading>
              <Link to={`/job/${slug}/`}>
                <Typo17
                  color="#658AC8;"
                  fontWeight="600"
                  as={small ? "h3" : "h2"}
                >
                  {name}
                </Typo17>
              </Link>
            </DesktopHeading>
            <SalaryText>
              <Typo18 color="rgba(11, 38, 76, 0.7)">
                {formattedSalary.length > 28 
                  && formattedSalary.length < 32 
                  && formattedSalary.indexOf('year') !== -1
                    ? <>
                        {formattedSalary.replace('year', 'y')}
                        <FullTimePoxtfix>ear</FullTimePoxtfix> 
                      </>
                    : formattedSalary
                }
              </Typo18>
            </SalaryText>
            <MobileTimeString>
              <Typo15 color="rgba(11, 38, 76, 0.67)">
                100% Remote; {time}
              </Typo15>
            </MobileTimeString>
          </SalaryTime>
          <ButtonSpace>
            <Link to={`/job/${slug}/`}>
              <ClickTarget>
                <Button>
                  <MoreArrow />
                </Button>
                <span className="sr-only">apply now</span>
              </ClickTarget>
            </Link>
          </ButtonSpace>
        </Row>
      </InformationCell>
      <TypeCell>
        <TypeBadge
          hint={isHireWise
            ? 'This role includes our shorter 2-step vetting process.'
            : 'This role includes our in-depth 3-step vetting process.'
          }
        >
          {isHireWise
            ? <HireWiseBrand />
            : <TalentMatchBrand />
          }
        </TypeBadge>
      </TypeCell>
      <TechCell>
        {!hasTech ? null : (
          <TechItems>
            {mustHaveTechItems.slice(0, 4).map((item) => {
              const { uid } = item;

              return (
                <TechItem key={uid || name} techItem={item} small={true} />
              );
            })}
            {mustHaveTechItems.length > 4 && (
              <TechItem
                techItem={{ uid: "...", name: "...", color: "" }}
                small={true}
              />
            )}
          </TechItems>
        )}
      </TechCell>
      <LocationCell>
        <CellPadding>
          <DesktopMainItem>
            <Typo17 color="#1B1B1B" fontWeight="500">
              {companyName}
            </Typo17>
          </DesktopMainItem>
        </CellPadding>
      </LocationCell>
      <TimeCell>
        <CellPadding>
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "30px" }}>
              <JobTimezoneContent 
                timezoneCategory={timezoneCategory}
                timezoneFormatted={timezoneFormatted}
                timeString={time}
                customLocation={customLocation}
              />
            </div>
            <div style={{ marginLeft: "auto", flexShrink: 0 }}>
              <Link to={`/job/${slug}/`}>
                <DesktopButton>
                  <Typo7 color="#fff" fontWeight="600">
                    Learn more
                  </Typo7>
                </DesktopButton>
              </Link>
              <JobListItemApplyNow
                applyUrl={applyUrl}
                slug={slug}
                hideInfo={isHireWise}
              />
            </div>
          </div>
        </CellPadding>
      </TimeCell>
    </Wrapper>
  );
};

interface JobTimezoneContentProps { 
  timezoneCategory: ITimezone | null
  timezoneFormatted: string | null
  timeString: string
  customLocation: string | null

}

function JobTimezoneContent(props: JobTimezoneContentProps) {
  const {
    timezoneCategory,
    timezoneFormatted,
    timeString,
    customLocation,
  } = props;

  if (customLocation) { 
    return (
      <DesktopMainItem>
        <Typo17 color="#1B1B1B" fontWeight="500">
          {customLocation}
        </Typo17>
      </DesktopMainItem>
    )
  }

  return (
    <>
      {!timezoneCategory && !timezoneFormatted ? null : (
        <DesktopMainItem>
          <Typo17 color="#1B1B1B" fontWeight="500">
            {timezoneCategory
              ? timezoneCategory.name
              : timezoneFormatted}
          </Typo17>
        </DesktopMainItem>
      )}
      <Typo14 color="rgba(11, 38, 76, 0.7)">{timeString}</Typo14>
    </>
  )
}

export default React.memo(JobListItemNew);
