import React from "react";
import Headings from "../../interfaces/Headings";
import IStrapiImage from "../../interfaces/IStrapiImage";
import StrapiImage from "../StrapiImage";
import { Heading14 } from "../Typography";
import {
  ImagePadding,
  ImagePosition,
  ImageWrapper,
  StyledGradientHero,
} from "./styles";

interface IProps {
  text: string;
  image?: IStrapiImage | null;
  tag?: Headings
}

const GradientHero: React.FC<IProps> = ({ text, image, tag }) => {
  return (
    <StyledGradientHero noImage={typeof image === 'undefined'}>
      { tag
        ? <Heading14
          fontWeight="700"
          color="#1B1B1B"
          textAlign="center"
          as={tag ? tag : undefined}
        >
          {text}
        </Heading14>
        : <Heading14
          fontWeight="700"
          color="#1B1B1B"
          textAlign="center"
          as="div"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      }
      {!image ? null : (
        <ImageWrapper>
          <ImagePadding>
            <ImagePosition>
              <StrapiImage image={image} />
            </ImagePosition>
          </ImagePadding>
        </ImageWrapper>
      )}
    </StyledGradientHero>
  );
};

export default GradientHero;
