import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

interface ICell {
  small?: boolean;
}

export const InformationCellStyled = styled.td<ICell>`
  display: block;

  @media ${media.tablet} {
    display: table-cell;
    vertical-align: top;
    width: 30%;
    padding-right: 10px;
    ${({ small }) =>
      small
        ? 'padding-bottom: 13px;'
        : css`
            padding-top: 12px;
            padding-bottom: 12px;
          `}
  }

  @media ${media.desktop} {
    width: 34%;
  }
`;

export const TechCellStyled = styled.td<ICell>`
  display: block;

  @media ${media.tablet} {
    display: table-cell;
    vertical-align: top;
    width: 16%;
    padding-right: 10px;
    ${({ small }) =>
      small
        ? 'padding-bottom: 13px;'
        : css`
            padding-top: 12px;
            padding-bottom: 12px;
          `}
  }
`;

export const LocationCellStyled = styled.td<ICell>`
  display: none;

  @media ${media.tablet} {
    display: table-cell;
    vertical-align: top;
    width: 12%;
    padding-right: 10px;
    ${({ small }) =>
      small
        ? 'padding-bottom: 13px;'
        : css`
            padding-top: 12px;
            padding-bottom: 12px;
          `}
  }
`;

export const TimeCellStyled = styled.td<ICell>`
  display: none;

  @media ${media.tablet} {
    display: table-cell;
    vertical-align: top;
    width: 31%;
    ${({ small }) =>
      small
        ? 'padding-bottom: 13px;'
        : css`
            padding-top: 12px;
            padding-bottom: 12px;
          `}
  }

  @media ${media.desktop} {
    width: 26%;
  }
`;

export const TypeCellStyled = styled.td<ICell>`
  display: block;
  padding-top: 10px;

  @media ${media.tablet} {
    display: table-cell;
    vertical-align: top;
    width: 12%;
    ${({ small }) =>
      small
        ? css`
            padding-top: 0;
            padding-bottom: 13px;
          `
        : css`
            padding-top: 17px;
            padding-bottom: 12px;
          `}
  }
`;
