import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const ApplyNowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  text-align: center;
  cursor: pointer;

  @media ${media.tablet} {
    margin-top: 3px;
  }
`;

export const ApplyNowText = styled.a`
  display: block;
  flex-shrink: 0;
  text-decoration: underline;
  text-decoration-color: #658ac8;

  &:hover {
    opacity: 0.85;
  }

  @media ${media.tablet} {
    letter-spacing: -0.01em;
  }
`;

export const ApplyTooltipTrigger = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 13px;
    margin-left: 6px;
    margin-top: 1px;
  }
`;
