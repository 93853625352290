import React from "react";
import IJob from "../../interfaces/IJob";
import JobListItemNew from "../JobListItemNew";
import { JobListTable } from "./styles";

interface IProps {
  jobs: IJob[];
  hideInfo?: boolean;
  section?: boolean;
}

const JobListNew: React.FC<IProps> = ({ jobs, hideInfo, section }) => {
  return (
    <JobListTable>
      <tbody>
        {jobs.map((job, index) => {
          const first = index === 0;

          const { id } = job;

          return (
            <JobListItemNew
              key={id}
              job={job}
              first={first}
              hideInfo={hideInfo}
              small={section}
            />
          );
        })}
      </tbody>
    </JobListTable>
  );
};

export default JobListNew;
