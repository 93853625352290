import React, { useRef, useState } from "react";
import useUpdate from "../../hooks/useUpdate";
import useWindowSize from "../../hooks/windowSize";
import ApplyInfo from "../ApplyInfo";

interface Props {
  lateHoverEvent?: Function;
}

const ApplyNowTooltip: React.FC<Props> = (props) => {
  const { children, lateHoverEvent } = props;

  const [show, setShow] = useState(false);

  const [section, setSection] = useState<null | string>(null);

  const [coords, setCoords] = useState([0, 0]);

  const timeout = useRef<any>();

  const ref = useRef(null);

  const windowSize = useWindowSize();

  useUpdate(() => {
    if (!ref.current || !show || !windowSize) return;

    const { width: windowWidth, height: windowHeight } = windowSize;

    const target = ref.current as HTMLElement;

    const targetRect = target.getBoundingClientRect();

    const { y, x } = targetRect;

    let section;

    if (y < windowHeight / 7) {
      section = "top";
    }
    if (y > (windowHeight * 6) / 7) {
      section = "bottom";
    } else if (y < windowHeight / 2 && x < windowWidth / 2) {
      section = "top-left";
    } else if (y < windowHeight / 2 && x > windowWidth / 2) {
      section = "top-right";
    } else if (y > windowHeight / 2 && x < windowWidth / 2) {
      section = "bottom-left";
    } else {
      section = "bottom-right";
    }

    setCoords([
      targetRect.x + targetRect.width / 2,
      targetRect.y + targetRect.height / 2,
    ]);
    setSection(section);
  }, [show, ref, windowSize]);

  useUpdate(() => {
    clearTimeout(timeout.current);

    if (!show) return;

    const remove = () => {
      setShow(false);
      window.removeEventListener("scroll", remove);
    };

    timeout.current = setTimeout(() => {
      lateHoverEvent && lateHoverEvent();
    }, 5000);

    return () => {
      window.addEventListener("scroll", remove);
      clearTimeout(timeout.current);
    };
  }, [show]);

  useUpdate(() => {
    if (show) return;

    setSection(null);
  }, [show]);

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
      {show && section !== null && coords[0] && coords[1] && (
        <ApplyInfo
          section={section}
          top={coords[1]}
          left={coords[0]}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default ApplyNowTooltip;
