import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Typo24 } from "../Typography";
import { media } from "../../common/MediaQueries";

const pulse = keyframes`
  0%, 100% { 
    opacity: 100%, 
  }

  60% { 
    opacity: 60%;
  }
`

interface Props {
  id?: string;
  url?: string;
  text?: string;
  margin?: number;
  big?: boolean;
  onClick?: Function;
  disabled?: boolean;
  as?: 'button' | 'a'
  loading?: boolean
}

interface IButton {
  margin?: number
  $big?: boolean
  disabled?: boolean
  $loading?: boolean
}

const Button = styled.a<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 44px;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.watermelon};

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.salmon};
    outline: none;
  }

  ${({ disabled, $loading }) =>
    Boolean(disabled || $loading)
      ? css`
          pointer-events: none;
          cursor: auto;
        `
      : null
  }

  ${({ disabled }) => 
    disabled
      ? css`
        &,
        &:hover,
        &:focus,
        &:active {
          background: #ffdce2;
        }
      `
      : null
  }  

  ${({ $loading }) => 
    $loading
      ? css`
        & { 
          background: ${({ theme }) => theme.colors.salmon};
          animation: ${pulse} infinite 1.5s linear;
        }
      `
      : null
  }

  ${({ margin }) =>
    typeof margin !== "undefined" ? `margin-top: ${margin}px` : ""};

  @media ${media.desktop} {
    min-height: ${({ $big }) => ($big ? 60 : 52)}px;
  }
`;

const ApplyButton: React.FC<Props> = (props) => {
  const {
    id,
    url,
    text,
    margin,
    big,
    onClick,
    disabled,
    as,
    loading,
  } = props;

  const NormalizedButton = Button as any;

  return (
    <NormalizedButton
      id={id}
      data-testid={id}
      href={url}
      target="_blank"
      margin={margin}
      as={as ? as : (onClick ? "button" : "a")}
      $big={big}
      disabled={disabled}
      $loading={loading}
      onClick={onClick}
    >
      <Typo24 color="#fff" fontWeight="600" style={{ pointerEvents: "none" }}>
        {text || "Apply Now"}
      </Typo24>
    </NormalizedButton>
  );
};

export default ApplyButton;
