const presentRegex = (key: string) => new RegExp(`[?&]${key}=`, 'i');

export default function decorateUrl(url: string, items: any) {
  if (!url) return '';

  const keysWithValues = Object.keys(items).filter(key => items[key]);

  const notPresentKeys = keysWithValues.filter(key => {
    return !presentRegex(key).test(url);
  });

  const parts = notPresentKeys.map(key => `${key}=${items[key]}`);

  const query = parts.join('&');

  const search = query ? `${url.indexOf('?') !== -1 ? '&' : '?'}${query}` : '';

  return `${url}${search}`;
}
