import styled, { css } from 'styled-components';

interface IApplyInfoArrowWrapper {
  section: string;
}

export const ApplyInfoTransformWrapper = styled.div<IApplyInfoArrowWrapper>`
  position: fixed;
  box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.1);
  z-index: 10;
  pointer-events: none;

  ${({ section }) => {
    switch (section) {
      case 'top-right':
        return css`
          transform: translate(calc(-100% - 13px), -25%);
        `;
      case 'bottom-right':
        return css`
          transform: translate(calc(-100% - 13px), -75%);
        `;
      case 'top-left':
        return css`
          transform: translate(26px, -25%);
        `;
      case 'bottom-left':
        return css`
          transform: translate(26px, -75%);
        `;
      case 'top':
        return css`
          transform: translate(-50%, calc(-100% - 10px));
        `;
      case 'bottom':
        return css`
          transform: translate(-50%, calc(-100% - 10px));
        `;
    }
  }}
`;

export const ApplyInfoWrapper = styled.div`
  position: relative;
  width: 455px;
  padding: 30px 28px 33px 32px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

export const ApplyInfoContent = styled.div`
  margin-top: 21px;
`;

export const ApplyInfoHeading = styled.div`
  padding: 0 3px;
`;

export const ApplyInfoItem = styled.div`
  position: relative;
  padding-left: 22px;
  padding-bottom: 28px;
  border-left: 1px solid #0b264c;

  &:nth-child(-1) {
    padding-bottom: 41px;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`;

export const ApplyInfoDot = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #0b264c;
  top: 0;
  left: 0;
  background: #fff;
  transform: translate(-50%, 0);
`;

export const ApplyInfoDotInner = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.colors.watermelon};
`;

export const ApplyInfoTimeWrapper = styled.div`
  display: flex;
  letter-spacing: -0.04em;
`;

export const ApplyInfoTimeIconWrapper = styled.div`
  width: 11px;
  height: 11px;
`;

export const ApplyInfoItemHeading = styled.div`
  padding-bottom: 4px;
`;

export const ApplyInfoText = styled.div`
  margin-top: 4px;
`;

interface ISection {
  section: string;
}

export const ApplyInfoArrow = styled.div<ISection>`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.1);

  ${({ section }) => {
    switch (section) {
      case 'top-right':
        return css`
          top: 25%;
          left: 100%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case 'bottom-right':
        return css`
          top: 75%;
          left: 100%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case 'top-left':
        return css`
          top: 25%;
          right: 100%;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case 'bottom-left':
        return css`
          top: 75%;
          right: 100%;
          transform: translate(50%, -50%) rotate(45deg);
        `;
      case 'top':
        return css`
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
      case 'bottom':
        return css`
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        `;
    }
  }}
`;
