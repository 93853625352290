import styled from 'styled-components';

import { media } from '../../common/MediaQueries';

interface IProps {
  first?: boolean;
}

// ${ ({ first }) => css`padding-top: ${ first ? '20px' : '25px' }` };
// padding-bottom: 20px;

export const Wrapper = styled.tr<IProps>`
  display: block;
  border-bottom: 1px solid rgba(11, 38, 76, 0.1);
  padding: 15px 0;
  overflow: hidden;

  @media ${media.tablet} {
    display: table-row;
    padding: 0;
    margin: 0;
  }
`;

export const MobileHeading = styled.td`
  display: block;

  &:hover {
    opacity: 0.85;
  }

  @media ${media.tablet} {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media ${media.tablet} {
    position: relative;
    margin-top: 0;
    min-height: 67px;
    align-items: center;
  }
`;

export const DesktopHeading = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  overflow: hidden;
  background: #f7f7f7;
  flex-shrink: 0;

  & img {
    border-radius: 6px;
  }

  & .gatsby-image-wrapper {
    display: block;
  }

  @media ${media.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    width: 60px;
    height: 60px;
    border-radius: 10px;

    & img {
      border-radius: 10px;
    }
  }
`;

export const SalaryTime = styled.div`
  margin-left: 10px;

  @media ${media.tablet} {
    margin-left: 0;
    padding-left: 85px;
  }
`;

export const SalaryText = styled.div`
  font-weight: 500;

  @media ${media.tablet} {
    font-weight: 400;
    letter-spacing: -0.01em;
    margin-top: 2px;
  }
`;

export const MobileTimeString = styled.div`
  margin-top: 4px;

  @media ${media.tablet} {
    display: none;
  }
`;

export const ButtonSpace = styled.div`
  margin-left: auto;

  @media ${media.tablet} {
    display: none;
  }
`;

export const ClickTarget = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.watermelon};

  &:hover {
    background: ${({ theme }) => theme.colors.salmon};
  }
`;

export const TechItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;

  @media ${media.tablet} {
    margin-top: 0px;
  }
`;

export const DesktopMainItem = styled.div`
  margin-bottom: 6px;
`;

export const CellPadding = styled.div`
  padding-top: 6px;
`;

export const DesktopButton = styled.div`
  padding: 4px 15px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.watermelon};

  &:hover {
    background: ${({ theme }) => theme.colors.salmon};
  }
`;

export const FullTimePoxtfix = styled.span`
  display: none;

  @media ${media.tablet} {
    display: inline;
  }
`;
