import styled from 'styled-components';

import { media } from '../../common/MediaQueries';

interface StyledGradientHeroProps {
  noImage?: boolean;
}

export const StyledGradientHero = styled.div<StyledGradientHeroProps>`
  position: relative;
  padding: ${({ noImage }) => (noImage ? '20px' : '14px 20px 20px 20px')};
  background: ${({ theme }) => theme.gradients.mist};
  min-height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.phone} {
    min-height: 0;
    display: block;
  }

  @media ${media.tablet} {
    padding: ${({ noImage }) => (noImage ? '25px 60px' : '17px 60px 25px 60px')};

    & br {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    width: 100%;
    margin: auto;
    max-width: 950px;
    padding-top: 13px;
  }
`;

export const ImagePadding = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 11.837%;
`;

export const ImagePosition = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export default StyledGradientHero;
