import React from "react";

import {
  InformationCellStyled,
  LocationCellStyled,
  TypeCellStyled,
  TechCellStyled,
  TimeCellStyled,
} from "./styles";

interface ICell {
  small?: boolean;
}

export const InformationCell: React.FC<ICell> = ({ children, small }) => {
  return (
    <InformationCellStyled small={small}>{children}</InformationCellStyled>
  );
};

export const TechCell: React.FC<ICell> = ({ children, small }) => {
  return <TechCellStyled small={small}>{children}</TechCellStyled>;
};

export const LocationCell: React.FC<ICell> = ({ children, small }) => {
  return <LocationCellStyled small={small}>{children}</LocationCellStyled>;
};

export const TimeCell: React.FC<ICell> = ({ children, small }) => {
  return <TimeCellStyled small={small}>{children}</TimeCellStyled>;
};

export const TypeCell: React.FC<ICell> = ({ children, small }) => {
  return <TypeCellStyled small={small}>{children}</TypeCellStyled>;
}; 
