export default function normalizeJobs(jobs: any) {
  if (!jobs) return [];

  const validJobs = jobs.filter((job: any) => job.slug && job.company);

  const formatted = validJobs
    .map((job: any) => {
      const {
        theGoodStuff,
        main,
        secondary,
        roleTypes,
        mustHaveTechItems,
        niceToHaveTechItems,
        visaSponsorship,
        faqGroup,
        applyUrl,
        seo,
        countries,
        isHireWise,
        timezoneCategory: unformattedTimezoneCategory,
      } = job;

      const timezoneCategory =
        !unformattedTimezoneCategory || unformattedTimezoneCategory.name === 'Other'
          ? null
          : unformattedTimezoneCategory;

      return {
        ...job,
        isHireWise: isHireWise || false,
        visaSponsorship: visaSponsorship || false,
        theGoodStuff: theGoodStuff || [],
        roleTypes: roleTypes || [],
        mustHaveTechItems: mustHaveTechItems || [],
        niceToHaveTechItems: niceToHaveTechItems || [],
        main: main || [],
        secondary: secondary || [],
        faqs: faqGroup?.faqs || [],
        applyUrl: applyUrl || 'https://www.flatworld.co',
        seo: seo || {},
        countries: countries || [],
        timezoneCategory,
      };
    })
    // role types
    .map((job: any) => {
      const { roleTypes: unformattedRoleTypes } = job;

      const roleTypes = unformattedRoleTypes
        .filter((item: any) => item.type && item.uid)
        .map((item: any) => ({ ...item, name: item.type }));

      return {
        ...job,
        roleTypes,
      };
    })
    // tech
    .map((job: any) => {
      const { mustHaveTechItems: unformattedMust, niceToHaveTechItems: unformattedNice } = job;

      const formatTechItem = (item: any) => ({
        ...item,
        color: item.color ? item.color : '#dedede',
        aliases: (item.aliases || [])
          .map((alias: any) => alias.alias)
          .filter((alias: string) => alias),
      });

      const mustHaveTechItems = unformattedMust
        .filter((item: any) => item.name)
        .map(formatTechItem);
      const niceToHaveTechItems = unformattedNice
        .filter((item: any) => item.name)
        .map(formatTechItem);

      return {
        ...job,
        mustHaveTechItems,
        niceToHaveTechItems,
      };
    })
    // countries
    .map((job: any) => {
      const { countries: unformattedCountries } = job;

      const countries = unformattedCountries.filter((item: any) => item.name);

      return {
        ...job,
        countries,
      };
    })
    // main, secondary
    .map((job: any) => {
      const { main: unformattedMain, secondary: unformattedSecondary } = job;

      const main = unformattedMain.filter((item: any) => item.heading && item.content);
      const secondary = unformattedSecondary.filter((item: any) => item.heading && item.content);

      return {
        ...job,
        main,
        secondary,
      };
    })
    // the good stuff
    .map((job: any) => {
      const { theGoodStuff: unformattedGoodStuff } = job;

      const theGoodStuff = unformattedGoodStuff.filter((item: any) => item.heading);

      return {
        ...job,
        theGoodStuff,
      };
    })
    .map((job: any) => {
      const { seo } = job;

      const { title, description, image, author } = seo;

      return {
        ...job,
        seo: {
          title: title || '',
          description: description || '',
          image: image || null,
          author: author || '',
        },
      };
    });

  return formatted;
}
