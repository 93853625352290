import React, { createContext, useState } from "react";
import IApplyStep from "../interfaces/IApplyStep";

interface Props {
  heading: string;
  steps: IApplyStep[];
}

export interface IApplyStepsContext {
  heading: string;
  steps: IApplyStep[];
}

const initial = {
  heading: "",
  steps: [],
};

export const ApplyStepsContext = createContext<IApplyStepsContext>(initial);

const ApplyStepsContextProvider: React.FC<Props> = ({
  children,
  heading,
  steps,
}) => {
  const value: IApplyStepsContext = {
    heading,
    steps,
  };

  return (
    <ApplyStepsContext.Provider value={value}>
      {children}
    </ApplyStepsContext.Provider>
  );
};

export default ApplyStepsContextProvider;
