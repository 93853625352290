import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

interface HintProps {
  below?: boolean;
}

const Hint = styled.span<HintProps>`
  position: absolute;
  opacity: 0; 
  pointer-events: none; 
  font-size: 12px; 
  line-height: 16px; 
  width: 200px;
  z-index: 10; 
  border-radius: 8px; 
  border: 1px solid #00214733;
  box-shadow: 3px 3px 3px #00000009;
  color: rgba(11,38,76,0.7);
  font-weight: 500;

  ${({ below }) => below
    ? css`
      left: 50%; 
      top: calc(100% + 8px); 
      transform: translate(-50%, 0);
    `
    : css`
      left: calc(100% + 8px); 
      top: 50%; 
      transform: translate(0, -50%);
    `
  }

  @media ${media.tablet} {
    width: 300px;
  }

  @media ${media.desktop} {
    font-size: 14px; 
    line-height: 18px; 
  }
`

const Content = styled.span`
  display: block;
  position: relative;
  background: #fff; 
  padding: 8px;
  border-radius: 8px;
  oveflow: hidden;
  text-align: center;

  @media ${media.tablet} {
    padding: 8px 12px;
  }
`

const Arrow = styled.span<HintProps>`
  display: block; 
  position: absolute; 
  
  width: 12px; 
  height: 12px; 
  border-radius: 3px; 
  background: #fff; 
  border: 1px solid #00214733;

  ${({ below }) => below
    ? css`
      left: 50%; 
      top: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    `
    : css`
      left: 0; 
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    `
  }
`

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  padding: 3px 8px; 
  background: #00214722;
  border-radius: 20px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  @media ${media.tablet} {
    padding: 4px 8px;
  }

  @media ${media.desktop} {
    padding: 6px 12px;
    font-size: 13px;
    line-height: 13px;
  }

  &:hover ${Hint} { 
    opacity: 1;
  }
`

interface Props { 
  below?: boolean
  hint?: string
}

const TypeBadge : React.FC<Props> = props => {

  const {
    children,
    below,
    hint,
  } = props;

  return (
    <Wrapper>
      {children}
      {hint 
        ? <Hint below={below}>
          <Arrow below={below} />
          <Content>
            {hint}
          </Content>
        </Hint>
        : null
      }
    </Wrapper>
  );
}

export default TypeBadge;